import { notification  } from 'antd';
export default function toImg(imgSrcList: any, fileName: any) {
  let src = imgSrcList;
  let tempImage = new Image();
  tempImage.src = src;
  tempImage.crossOrigin = "*";
  tempImage.onload = ()=> {	
    console.log(getBase64Image(tempImage)) 
    downloadFile((fileName) + '.jpg',getBase64Image(tempImage)) 
  }
}
function downloadFile(fileName: string, content: string) {
let aLink = document.createElement('a');
let blob = base64ToBlob(content); //new Blob([content]);

let evt = document.createEvent("HTMLEvents");        evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
aLink.download = fileName;
aLink.href = URL.createObjectURL(blob);

  // aLink.dispatchEvent(evt);
  //aLink.click()
  aLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));//兼容火狐
  setTimeout(()=>{
			notification['success']({
				message: '下载提示',
				description:
					'下载完毕',
			});
	},500)
}
function	base64ToBlob(code: string) {
  let parts = code.split(';base64,');
  let contentType = parts[0].split(':')[1];
  let raw = window.atob(parts[1]);
  let rawLength = raw.length;

  let uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
}
return new Blob([uInt8Array], {type: contentType});
}
function getBase64Image(img:any) {
let canvas = document.createElement("canvas");    
canvas.width = img.width;
canvas.height = img.height;
let ctx:any = canvas.getContext("2d");
ctx.drawImage(img, 0, 0, img.width, img.height);
let ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();
let dataURL = canvas.toDataURL("image/"+ext);
return dataURL;
}
